import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { snakeCase } from 'lodash'
import { useAnalytics as useAnalyticsBase } from 'hooks/analytics'
import {
  GOOGLE_ANALYTICS_TEST_MODE,
  GOOGLE_TAG_MANAGER_KEY,
  GOOGLE_MEASUREMENT_ID,
} from 'data/constants'
import { getUserData, isUserLogged } from 'redux-modules/user/user.selectors'

export enum TrackCategory {
  User = 'user',
  Encounters = 'cvp_encounters',
  Loitering = 'cvp_loitering',
  GeneralActions = 'cvp_general_actions',
  SearchVessel = 'cvp_search_vessel',
  VesselHistory = 'cvp_vessel_history',
}

export type TrackEventParams<T> = {
  category: T | TrackCategory
  action: string
  label?: string
  value?: any
}

export const trackEvent = <T>({ category, action, label, value }: TrackEventParams<T>) => {
  /**
   * IMPORTANT
   *
   * To send the category and action in snake_case to GA4
   * it is necessary to use this:
   * ```
   * ReactGA.event(name, params)
   * ```
   * method signature so they won't be converted to title case.
   *
   * https://github.com/codler/react-ga4/issues/15
   */
  ReactGA.event(snakeCase(action), {
    category: snakeCase((category as string) ?? ''),
    label: label,
    value,
  })
}

export const useAnalytics = () => {
  const user = useSelector(getUserData)
  const logged = useSelector(isUserLogged)
  const pageview =
    typeof window !== 'undefined' ? window.location.pathname + window.location.search : ''
  useAnalyticsBase({
    debugMode: GOOGLE_ANALYTICS_TEST_MODE,
    googleMeasurementId: GOOGLE_MEASUREMENT_ID,
    googleTagManagerId: GOOGLE_TAG_MANAGER_KEY,
    logged,
    pageview,
    user,
  })
}
