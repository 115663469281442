import React, { lazy, Suspense, Fragment, useState } from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import styles from 'app.module.css'
import { LOGIN } from 'routes'
import Header from 'components/header/header.container'
import Menu from 'components/menu/menu'
import Loader from 'components/loader/loader'
import { useAnalytics } from 'app/analytics.hooks'
import WelcomeModal from 'components/welcome/welcome'
import { getLocationType } from 'redux-modules/router/route.selectors'
import LoginComponent from './pages/login/login'

declare global {
  interface Window {
    gtag: any
  }
}

Modal.setAppElement('#root')
const HomePageComponent = lazy(() => import(`./pages/home/home.container`))

const App: React.FC = (): React.ReactElement => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const locationType = useSelector(getLocationType)
  useAnalytics()

  if (locationType === LOGIN) return <LoginComponent />

  return (
    <Fragment>
      <Header showMenu={setShowMenu} />
      <nav className={cx(styles.menuWrapper, { [styles.hidden]: !showMenu })}>
        <Menu onCloseClick={() => setShowMenu(false)} />
        <div className={cx('veil', styles.menuVeil)} onClick={() => setShowMenu(false)} />
      </nav>
      <Suspense fallback={<Loader />}>
        <HomePageComponent />
      </Suspense>
      <WelcomeModal />
    </Fragment>
  )
}

export default App
