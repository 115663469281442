import { useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { InitOptions } from 'react-ga4/types/ga4'
import { snakeCase } from 'lodash'
import { UserData } from '@globalfishingwatch/api-types'

export type useAnalyticsParams = {
  debugMode?: boolean
  googleMeasurementId?: string
  googleTagManagerId?: string
  gtagUrl?: string
  isLoading?: boolean
  logged: boolean
  pageview?: string
  user: UserData | null | undefined
}

export const useAnalytics = ({
  debugMode = false,
  googleMeasurementId,
  googleTagManagerId,
  gtagUrl = 'https://www.googletagmanager.com/gtm.js',
  isLoading = false,
  logged,
  pageview = '',
  user,
}: useAnalyticsParams) => {
  const {
    config,
    initGtagOptions,
  }: { config: InitOptions[]; initGtagOptions: any } = useMemo(() => {
    const config: InitOptions[] = []
    const initGtagOptions: any = {
      // Debug Mode enables the usage of Debug View in Google Analytics > Admin
      ...(debugMode ? { debug_mode: 1 } : {}),
    }
    if (googleTagManagerId) {
      config.push({ trackingId: googleTagManagerId })
      initGtagOptions.gtagUrl = gtagUrl
    }
    if (googleMeasurementId) {
      config.push({ trackingId: googleMeasurementId })
    }
    return { config, initGtagOptions }
  }, [debugMode, googleMeasurementId, googleTagManagerId, gtagUrl])

  useEffect(() => {
    if (config.length > 0) {
      ReactGA.initialize(config, initGtagOptions)
      // Tip: Uncomment this to prevent sending hits to GA
      // ReactGA.set({ sendHitTask: null })
    }
  }, [config, initGtagOptions])

  useEffect(() => {
    if (config.length > 0) {
      ReactGA.send({ hitType: 'pageview', page: pageview })
    }
  }, [config.length, pageview])

  useEffect(() => {
    if (config.length === 0 || isLoading) return

    if (user) {
      ReactGA.set({
        user_country: user.country ?? '',
        user_group: user.groups ?? '',
        user_org_type: user.organizationType ?? '',
        user_organization: user.organization ?? '',
        user_language: user.language ?? '',
      })
      ReactGA.event(snakeCase('Login'), {
        category: 'user',
      })
    } else {
      ReactGA.set({
        user_country: '',
        user_group: '',
        user_org_type: '',
        user_organization: '',
        user_language: '',
      })
    }
  }, [user, config.length, isLoading])
}
